// GalleryApp.js
import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Player } from "video-react";
import img1 from "../../styles/news-1.jpg";
import img2 from "../../styles/news-2.jpg";
import img3 from "../../styles/news-3.jpg";
import img4 from "../../styles/news-4.jpg";
import img5 from "../../styles/news-5.jpg";
import img6 from "../../styles/news-6.jpg";
import { Link } from "react-router-dom";
import vid from "../../image/real.mp4";
import "./Gallery.css";
import "../../../node_modules/video-react/dist/video-react.css";

const GalleryApp = () => {
  return (
    <section style={{ marginTop: "5rem" }}>
      <div className="page-wrapper">
        <div className="container mt-3">
          <div className="sec-title style-two centered">
            <h2>
              Our&nbsp;<span>Gallery</span>
            </h2>
            <Row style={{ marginTop: "5rem" }}>
              <Col md={8}>
                <Player
                  muted
                  autoPlay
                  loop
                  poster="/assets/poster.png"
                  src={vid}
                  // https://media.w3.org/2010/05/sintel/trailer_hd.mp4
                />
              </Col>

              <Col md={4}>
                <Row className="mb-3">
                  <Col md={6} className="p-2">
                    <Image
                      className="responsiveImg"
                      src={img1}
                      alt="Image1"
                      rounded
                      fluid
                    />
                  </Col>

                  <Col md={6} className="p-2">
                    <Image
                      src={img2}
                      alt="Image 2"
                      className="responsiveImg"
                      rounded
                      fluid
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="p-2">
                    <Image
                      src={img3}
                      alt="Image 3"
                      className="responsiveImg"
                      rounded
                      fluid
                    />
                  </Col>

                  <Col md={6} className="p-2">
                    <Image
                      src={img4}
                      alt="Image 4"
                      className="responsiveImg"
                      rounded
                      fluid
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="p-2">
                    <Image
                      src={img5}
                      alt="Image 5"
                      className="responsiveImg"
                      rounded
                      fluid
                    />
                  </Col>

                  <Col md={6} className="p-2">
                    <Image
                      src={img6}
                      alt="Image 6"
                      className="responsiveImg"
                      rounded
                      fluid
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={12}>
                    <Link to="/gallery">
                      <Button variant="primary" className="btn-responsive">
                        More
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryApp;

// import React from "react";
// import { Row, Col, Image, Button } from "react-bootstrap";
// import ReactPlayer from "react-player";
// import img1 from "../../styles/news-1.jpg";
// import img2 from "../../styles/news-2.jpg";
// import img3 from "../../styles/news-3.jpg";
// import img4 from "../../styles/news-4.jpg";
// import img5 from "../../styles/news-5.jpg";
// import img6 from "../../styles/news-6.jpg";
// import { Link } from "react-router-dom";
// import "./Gallery.css";

// const GalleryApp = () => {
//   return (
//     <section style={{ marginTop: "5rem" }}>
//       <div className="page-wrapper">
//         <div className="container mt-3">
//           <div className="sec-title style-two centered">
//             <h2>
//               Our&nbsp;<span>Gallery</span>
//             </h2>
//             <Row style={{ marginTop: "5rem" }}>
//               <Col md={8}>
//                 <ReactPlayer
//                   url="https://www.youtube.com/watch?v=oyA7Ucnmje4&t=72s"
//                   controls={true}
//                   playing
//                   loop
//                   muted
//                   width={"100%"}
//                   height={"396px"}
//                 />
//               </Col>

//               <Col md={4}>
//                 <Row className="mb-3">
//                   <Col
//                     md={6}
//                     style={{
//                       padding: "2px",
//                       marginLeft: "1.5rem",
//                       width: "17.9rem",
//                     }}
//                   >
//                     <Image src={img1} alt="Image1" rounded />
//                   </Col>

//                   <Col
//                     md={6}
//                     style={{
//                       padding: "2px",
//                       maxWidth: "14.9rem",
//                     }}
//                   >
//                     <Image
//                       src={img2}
//                       alt="Image 2"
//                       className="responsiveImg"
//                       rounded
//                       // style={{
//                       //   height: "11.1rem",
//                       //   marginRight: "5rem",
//                       //   width: "19.1rem",
//                       // }}
//                     />
//                   </Col>
//                 </Row>

//                 <Row>
//                   <Col
//                     md={6}
//                     style={{
//                       padding: "2px",
//                     }}
//                   >
//                     <Image
//                       src={img3}
//                       alt="Image 3"
//                       className="responsiveImg"
//                       rounded
//                       // style={{
//                       //   height: "11.1rem",
//                       //   marginRight: "5rem",
//                       //   width: "19.1rem",
//                       // }}
//                     />
//                   </Col>

//                   <Col md={6} style={{ padding: "2px" }}>
//                     <Image
//                       src={img4}
//                       alt="Image 4"
//                       className="responsiveImg"
//                       rounded
//                       // style={{
//                       //   height: "11.1rem",
//                       //   marginRight: "5rem",
//                       //   width: "19.1rem",
//                       // }}
//                     />
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={6} style={{ padding: "2px" }}>
//                     <Image
//                       src={img5}
//                       alt="Image 5"
//                       className="responsiveImg"
//                       rounded
//                       // style={{
//                       //   height: "11.1rem",
//                       //   marginRight: "5rem",
//                       //   width: "19.1rem",
//                       // }}
//                     />
//                   </Col>

//                   <Col md={6} style={{ padding: "2px" }}>
//                     <Image
//                       src={img6}
//                       alt="Image 6"
//                       className="responsiveImg"
//                       rounded
//                       // style={{
//                       //   height: "11.1rem",
//                       //   marginRight: "5rem",
//                       //   width: "19.1rem",
//                       // }}
//                     />
//                   </Col>
//                 </Row>

//                 <Row className="mt-3" style={{ marginTop: "2px" }}>
//                   <Col md={12}>
//                     <Link to="/gallery">
//                       <Button
//                         variant="primary"
//                         style={{
//                           width: "26vw",
//                           color: "white",
//                           backgroundColor: "#00052e",
//                         }}
//                       >
//                         More
//                       </Button>
//                     </Link>
//                   </Col>
//                 </Row>
//               </Col>
//             </Row>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default GalleryApp;
