import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";
import TopHeader from "./TopHeader";
import logo from "../image/logo.png";
const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <>
      <TopHeader />
      <nav className="navbar">
        <img className="logo" src={logo} alt="" />
        <ul
          className={isMobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setIsMobile(false)}
        >
          <Link
            to="/"
            className="home"
            activeStyle={{ backgroundColor: "yellow" }}
          >
            <li>Home</li>
          </Link>
          <Link
            to="/aboutus"
            className="about"
            activeStyle={{ backgroundColor: "yellow" }}
          >
            <li>About Us</li>
          </Link>
          <Link
            to="/projects"
            className="projects"
            activeStyle={{ backgroundColor: "yellow" }}
          >
            <li>Projects</li>
          </Link>
          <Link
            to="/gallery"
            className="gallery"
            activeStyle={{ backgroundColor: "yellow" }}
          >
            <li>Gallery</li>
          </Link>
          <Link
            to="/contactus"
            className="contact"
            activeStyle={{ backgroundColor: "yellow" }}
          >
            <li>Contact</li>
          </Link>
        </ul>
        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </button>
      </nav>
    </>
  );
};

export default Header;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import TopHeader from "./TopHeader";
// import "../styles/Header.css";

// const Header = () => {
//   const [isNavOpen, setIsNavOpen] = useState(false);

//   const handleToggleNav = () => {
//     setIsNavOpen(isNavOpen);
//   };

//   return (
//     <div className="page-wrapper">
//       <div className="preloader" />
// 1
//       <header className={`main-header ${isNavOpen ? "open" : ""}`}>
//         <TopHeader />
//         <div className="header-upper">
//           <div className="auto-container clearfix">
//             <div className="pull-left logo-outer">
//               <div className="logo">
//                 <Link to="/">
//                   <img src="images/logo.png" alt="RealArc Logo" />
//                 </Link>
//               </div>
//             </div>
//             <div className="pull-right upper-right clearfix">
//               <nav className="main-menu">
//                 <div className="navbar-header">
//                   <button
//                     type="button"
//                     className="navbar-toggle"
//                     onClick={handleToggleNav}
//                   >
//                     <span className="icon-bar" />
//                     <span className="icon-bar" />
//                     <span className="icon-bar" />
//                   </button>
//                 </div>
//                 <div className="navbar-collapse clearfix">
//                   <ul className="navigation clearfix">
//                     <li className="current">
//                       <Link to="/">Home</Link>
//                     </li>
//                     <li>
//                       <Link to="/aboutus">About Us</Link>
//                     </li>

//                     <li>
//                       <Link to="/projects">Projects</Link>
//                     </li>
//                     <li>
//                       <Link to="/gallery">Gallery</Link>
//                     </li>

//                     <li>
//                       <Link to="/contactus">Contact</Link>
//                     </li>
//                   </ul>
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>

//         {/* Additional Code for Sticky Header */}
//         <div className="sticky-header">
//           <div className="auto-container clearfix">
//             <div className="logo pull-left">
//               <a href="index" className="img-responsive">
//                 <img
//                   src="images/logo-small.png"
//                   alt="RealArc Logo"
//                   title="RealArc Logo"
//                 />
//               </a>
//             </div>
//             <div className="right-col pull-right">
//               <nav className="main-menu">
//                 <div className="navbar-header">
//                   <button
//                     type="button"
//                     className="navbar-toggle"
//                     onClick={handleToggleNav}
//                   >
//                     <span className="icon-bar" />
//                     <span className="icon-bar" />
//                     <span className="icon-bar" />
//                   </button>
//                 </div>
//                 <div className="navbar-collapse clearfix">
//                   <ul className="navigation clearfix">
//                     {/* ...Your sticky menu items here... */}
//                   </ul>
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// };

// export default Header;
